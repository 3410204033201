.red-row,
.red-row:hover {
  background-color: #fff4f4 !important;
}

.ku-font {
  font-family: 'Noto Kufi Arabic', sans-serif !important;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'),
    url(../fonts/English/Montserrat-Light.otf) format('truetype');
}

@font-face {
  font-family: 'Rabar_013';
  src: local('Rabar_013'),
    url(../fonts/Kurdish_Arabic/Rabar_013.ttf) format('truetype');
}

@font-face {
  font-family: 'Rabar_014';
  src: local('Rabar_014'),
    url(../fonts/Kurdish_Arabic/Rabar_014.ttf) format('truetype');
}

@font-face {
  font-family: 'UniQAIDAR_Blawkrawe';
  src: local('UniQAIDAR_Blawkrawe'),
    url(../fonts/Kurdish_Arabic/UniQAIDAR_Blawkrawe.ttf) format('truetype');
}

.main-wrapper[lang='en'] {
  font-family: 'Montserrat';
}

.main-wrapper[lang='ku'],
.main-wrapper[lang='ar'] {
  font-family: 'Rabar_014';
}

/* Dashboard */
#components-layout-demo-side .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}

.site-layout .site-layout-background {
  background: #fff;
}

.ant-menu-item-selected {
  background-color: #18c59f7d !important;
}

/* New Unit Contract styles */
.transactionItemContainer {
  display: grid;
  text-align: center;
  border-bottom: thin solid #f0f0f0;
  grid-template-columns: 50px 20% 20% 1fr 120px;
}

.transactionItemContainer > div {
  padding: 15px 0;
  align-self: center;
}

.transactionContainer {
  margin-bottom: 20px;
}

.transactionInput {
  position: relative;
  padding: 4px 6px;
  border-radius: 3px;
  border: thin solid #d9d9d9;
}

.transactionInputHeader {
  padding: 0px 0 6px 6px;
}

.autoGeneratePaymentBtn {
  right: 65px;
  top: 12px;
  position: absolute;
  background: #fff;
}

.autoGeneratePaymentBtn:hover {
  color: #ff6e40;
  background-color: #fff !important;
}

/* //////////////////////////////////////// */

/* antd popover */

.ant-popover {
  min-width: 200px !important;
}

/* Dashboard styles */
.iconWrapper {
  top: -58px;
  position: relative;
  margin-bottom: -40px;
}
.bigIconContainer {
  width: 70px;
  height: 70px;
  margin: auto;
  color: #fff;
  position: relative;
  border-radius: 100px;
  box-shadow: rgb(221 228 231) 0px -5px 9px;
}

.bigIconContainer > .anticon {
  font-size: 30px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.customGutter {
  margin: 50px -7.5px 5px;
}

.customGutter > .ant-col {
  padding: 5px 7.5px;
}

@media screen and (max-width: 992px) {
  .customGutter {
    margin: 50px -7.5px 27.5px;
  }

  .customGutter > .ant-col {
    padding: 27.5px 7.5px;
  }
}
